import { environment } from 'src/environments/environment.prod';

export class AppConstant {
    public static get WEBSITE_URL(): string { return environment.WEBSITE_URL }
    public static get PAGE_LIMIT(): Number { return 10 } 
    public static get HINDI_WEBSITE_URL() : string {return "https://hindi.sportstiger.com"}


    public static get CONFERMATION_NO(): string { return 'No' } 
    
    public static get CONFERMATION_YES(): string { return 'Yes' } 

    public static get USER_TOKEN_KEY(): string { return 'token' }

    public static get FAILED_MESSAGE(): string { return 'Failed' }

    public static get SUCCESS_MEASSAGE(): string { return 'Success' }

    public static get GREATER_THAN_ZERO(): string { return 'position should be grater than 0' }

    public static get DEVICE_TOKEN(): string { return 'deviceToken' }

    public static get LOGIN_DATA_KEY(): string { return 'userId' }

    public static get USER_INFO(): string { return 'userInfo' }

    public static get USER_TYPE_KEY(): string { return 'userType' }

    public static get MARK_DATA_KEY(): string { return 'mark as trending' }

    public static get UNMARK_DATA_KEY(): string { return 'un-mark as trending' }

    public static get BLANK_STRING(): string { return "" }



    public static get CKEDITOR_CONFIG() {
        return {
            versionCheck: false,
            forcePasteAsPlainText: true,
            height:500,
            toolbar : [
                 { name: 'basicstyles', items: ['Bold','Italic','Underline','Strike','Subscript','Superscript','BlockQuote'] },
                  { name: 'paragraph', items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote'] },
                  { name: 'insert', items: [ 'Table','Smiley','SpecialChar'] },
                  { name: 'document', items: [ 'Find','Replace'] },
                  { name: 'clipboard', items: [ 'Cut', 'Copy', 'Paste', 'Undo', 'Redo'] },
                  { name: 'lists', items: [ 'NumberedList','BulletedList','Outdent','Indent'] },
                  { name: 'link', items: ['Link','Unlink'] },
                  { name: 'styles', items: ['Format'] },
                  { name: 'align', items: [ 'JustifyLeft','JustifyCenter','JustifyRight','JustifyBlock'] },
                  { name: 'tools', items: ['Maximize'] },
                  { name: 'document', items: ['Source'] },
            ]
          }
    }
    /*  creating the Function For the Log */

    public static print(value) {
        let val = console.log(value);
        return val;
    }

}

